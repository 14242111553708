var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"overflow-auto scroll-none"},[_c('navbar-admin',[_c('el-button',{staticClass:"mr-1 btn-cerrar shadow-sm",attrs:{"size":"small"},on:{"click":_vm.cerrar}},[_vm._v("Cerrar")]),_c('el-button',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"mr-3 btn-general shadow-sm",attrs:{"size":"small"},on:{"click":_vm.validarAvance}},[_vm._v("Avanzar")])],1),_c('ValidationObserver',{ref:"validator"},[(_vm.ciclo)?_c('div',{staticClass:"row mx-0 p-4"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-middle-center"},[_c('p',{staticClass:"f-600 f-14 mr-3"},[_vm._v("Siguiente ciclo: ")]),(Object.values(_vm.infoCicloOpportunity).length)?_c('p',{staticClass:"f-14"},[_vm._v(_vm._s(_vm.infoCicloOpportunity.siguiente_ciclo.nombre))]):_vm._e()]),_c('ValidationProvider',{attrs:{"name":"fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-middle-center"},[_c('p',{staticClass:"f-14 mr-3 mb-1"},[_vm._v("Cierre próximo ciclo: ")]),_c('div',[_c('el-date-picker',{staticClass:"w-60",attrs:{"value-format":"yyyy-MM-dd","size":"small","type":"date"},model:{value:(_vm.fechaCierre),callback:function ($$v) {_vm.fechaCierre=$$v},expression:"fechaCierre"}}),_c('vee-error',{attrs:{"text":errors[0]}})],1)])]}}],null,false,1638066963)})],1),_c('titulo-divisor',{attrs:{"titulo":"Anotaciones"}}),_c('div',{staticClass:"row justify-content-between mb-5"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"f-10"},[_vm._v("Observaciones del avance")]),_c('ValidationProvider',{attrs:{"name":"Observaciones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"textarea","rows":4},model:{value:(_vm.observacionAvance),callback:function ($$v) {_vm.observacionAvance=$$v},expression:"observacionAvance"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,false,2830323212)})],1),_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"f-10"},[_vm._v("Estrategia para el siguiente ciclo")]),_c('ValidationProvider',{attrs:{"name":"estrategia","rules":_vm.requireEstrategia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"textarea","rows":4},model:{value:(_vm.estrategiaCiclo),callback:function ($$v) {_vm.estrategiaCiclo=$$v},expression:"estrategiaCiclo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,false,3385508610)})],1)]),(_vm.required_documents.length)?_c('div',[_c('titulo-divisor',{attrs:{"titulo":"Documentación","subTitulo":"*Obligatorios"}}),_c('upload-file',{attrs:{"archivosRequeridos":_vm.required_documents},on:{"setDocuments":_vm.setDocuments}})],1):_vm._e(),(_vm.additional_data.length)?_c('div',[_c('titulo-divisor',{attrs:{"titulo":"Datos adicionales","subTitulo":"*Obligatorios"}}),_vm._l((_vm.additional_data),function(item,index){return _c('div',{key:index},[(item.tipo == 1)?_c('div',{staticClass:"d-middle justify-content-between mb-1"},[_c('p',{staticClass:"f-12"},[_vm._v(_vm._s(item.nombre)+" "),(_vm.campoObligatorio(item.obligatorio))?_c('required'):_vm._e()],1),_c('ValidationProvider',{staticClass:"w-50",attrs:{"name":item.nombre,"rules":{'required': item.obligatorio == 1}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":item.nombre},model:{value:(item.respuesta),callback:function ($$v) {_vm.$set(item, "respuesta", $$v)},expression:"item.respuesta"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,true)})],1):_vm._e(),(item.tipo == 2)?_c('div',{staticClass:"d-middle justify-content-between mb-1"},[_c('p',{staticClass:"f-12"},[_vm._v(_vm._s(item.nombre)+" "),(_vm.campoObligatorio(item.obligatorio))?_c('required'):_vm._e()],1),_c('ValidationProvider',{staticClass:"w-50",attrs:{"name":item.nombre,"rules":{'required': item.obligatorio == 1}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input-number',{staticClass:"w-100",attrs:{"size":"small","controls-position":"right","min":0},model:{value:(item.respuesta),callback:function ($$v) {_vm.$set(item, "respuesta", $$v)},expression:"item.respuesta"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,true)})],1):_vm._e(),(item.tipo == 3)?_c('div',{staticClass:"d-middle justify-content-between mb-1"},[_c('p',{staticClass:"f-12"},[_vm._v(_vm._s(item.nombre)+" "),(_vm.campoObligatorio(item.obligatorio))?_c('required'):_vm._e()],1),_c('ValidationProvider',{staticClass:"w-50",attrs:{"name":item.nombre,"rules":{'required': item.obligatorio == 1}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":item.nombre},model:{value:(item.respuesta),callback:function ($$v) {_vm.$set(item, "respuesta", $$v)},expression:"item.respuesta"}},_vm._l((item.oportunidades_ciclos_datos_opciones),function(datos){return _c('el-option',{key:datos.id,attrs:{"label":datos.nombre,"value":datos.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,true)})],1):_vm._e()])})],2):_vm._e()],1)]):_c('div',{staticClass:"d-middle-center"},[_c('p',{staticClass:"mt-5 text-muted f-600 f-18"},[_vm._v("Ciclo no encontrado...")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }