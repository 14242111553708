<template>
    <section>
        <div class="">
            <div class="mx-4 border-bottom my-2" v-for="(item, index) in archivosRequeridos" :key="index">
                <div class="row">
                    <div class="d-flex align-items-center col-6">
                        <i class="icon-paperclip f-22 text-general" />
                        <p class="f-14">{{ item.nombre }} <required v-if="item.obligatorio == 1"/></p>
                    </div>
                    <div class="d-flex align-items-center col-6">
                        <div v-if="fileUp(index)" class="d-middle-center tres-puntos card-file">
                            <div class="d-middle-center tres-puntos">
                                <i class=" f-22 text-5d" :class="fileUp(index).type" />
                                <p class="f-12 tres-puntos">{{fileUp(index).file.name}}</p>
                            </div>
                            <i class="icon-close-circle f-17 text-danger cr-pointer d-none" @click="removeFile(fileUp(index))"/>
                            <i class="icon-ok-circled-outline f-17 text-success cr-pointer" @click="removeFile(fileUp(index))"/>
                        </div>
                        <div v-else class="btn p-0 mb-1 option" @click="$refs[`uploaderInput-${index}`][0].click()">
                            <i class="text-white f-20 icon-upload-outline"/>
                        </div>
                        <input
                            :ref="`uploaderInput-${index}`"
                            class="d-none" type="file"
                            name="fileUploader" @change="setFiles($event, index, item)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        archivosRequeridos: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            filesToUpload: [],
        }
    },
    methods: {
        fileUp(index) {
            return this.filesToUpload.find( f => f.index === index ) ?? null
        },
        setFiles(evt, index, item){
            const type = this.getIconExtension(evt.target.files[0].name)
            this.filesToUpload.push({index, file: evt.target.files[0], type, id_documento: item.id, obligatorio: item.obligatorio})
            this.$refs[`uploaderInput-${index}`][0].value = ''
            this.$emit("setDocuments", this.filesToUpload)
        },
        getIconExtension(nameFile){
            let icon = 'icon-file text-muted'
            this.$try( () => {
                const extension = nameFile.split(".").pop();
                switch (extension) {
                    case 'txt' || 'docx' || 'docm' || 'dotx':
                        icon = 'icon-file-document-edit text-general'
                        break;
                    case 'pdf':
                        icon = 'icon-pdf text-danger'
                        break;
                    case 'png' || 'jpg' || 'jpeg':
                        icon = 'icon-image text-success'
                        break;
                    default:
                        icon = 'icon-file text-muted'
                        break;
                }
            })
            return icon
        },
        removeFile(file){
            this.filesToUpload = this.filesToUpload.filter(upload => upload !== file)
            this.$emit("setDocuments", this.filesToUpload)
        },
    },
}
</script>

<style lang="scss" scoped>
    .card-file:hover{
        .icon-ok-circled-outline{
            display: none;
        }
        .icon-close-circle{
            display: block !important;
        }
    }
   .option{
        background: #808080;
        &:hover{
            background: var(--color-general);
        }
    }
</style>