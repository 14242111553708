<template>
    <section class="overflow-auto scroll-none">
        <navbar-admin>
            <el-button size="small" class="mr-1 btn-cerrar shadow-sm" @click="cerrar">Cerrar</el-button>
            <el-button size="small" class="mr-3 btn-general shadow-sm" v-loading.fullscreen.lock="fullscreenLoading" @click="validarAvance">Avanzar</el-button>
        </navbar-admin>
        <ValidationObserver ref="validator">
            <div v-if="ciclo" class="row mx-0 p-4">
                <div class="col-8">
                    <div class="d-flex justify-content-between">
                        <div class="d-middle-center">
                            <p class="f-600 f-14 mr-3">Siguiente ciclo: </p>
                            <p v-if="Object.values(infoCicloOpportunity).length" class="f-14">{{ infoCicloOpportunity.siguiente_ciclo.nombre }}</p>
                        </div>
                        <ValidationProvider v-slot="{ errors }" name="fecha" rules="required">
                            <div class="d-middle-center">
                                <p class="f-14 mr-3 mb-1">Cierre próximo ciclo: </p>
                                <div>
                                    <el-date-picker v-model="fechaCierre" value-format="yyyy-MM-dd" size="small" type="date" class="w-60" />
                                    <vee-error :text="errors[0]"></vee-error>
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                    <!-- Anotaciones -->
                    <titulo-divisor titulo="Anotaciones" />
                    <div class="row justify-content-between mb-5">
                        <div class="col-6">
                            <p class="f-10">Observaciones del avance</p>
                            <ValidationProvider v-slot="{ errors }" name="Observaciones" rules="required">
                                <el-input
                                    type="textarea"
                                    :rows="4"
                                    v-model="observacionAvance">
                                </el-input>
                                <vee-error :text="errors[0]"></vee-error>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <p class="f-10">Estrategia para el siguiente ciclo</p>
                            <ValidationProvider v-slot="{ errors }" name="estrategia" :rules="requireEstrategia">
                                <el-input
                                    type="textarea"
                                    :rows="4"
                                    v-model="estrategiaCiclo">
                                </el-input>
                                <vee-error :text="errors[0]"></vee-error>
                            </ValidationProvider>
                        </div>
                    </div>
                    <!-- Documentacion -->
                    <div v-if="required_documents.length">
                        <titulo-divisor titulo="Documentación" subTitulo="*Obligatorios" />
                        <upload-file :archivosRequeridos="required_documents" @setDocuments="setDocuments"/>
                    </div>
                    <!-- Datos adicionales -->
                    <div v-if="additional_data.length">
                        <titulo-divisor titulo="Datos adicionales" subTitulo="*Obligatorios" />
                        <div v-for="(item, index) in additional_data" :key="index">
                            <div v-if="item.tipo == 1" class="d-middle justify-content-between mb-1">
                                <p class="f-12">{{ item.nombre }} <required v-if="campoObligatorio(item.obligatorio)"/></p>
                                <ValidationProvider class="w-50" v-slot="{ errors }" :name="item.nombre" :rules="{'required': item.obligatorio == 1}">
                                    <el-input v-model="item.respuesta" size="small" class="w-100" :placeholder="item.nombre" />
                                    <vee-error :text="errors[0]"></vee-error>
                                </ValidationProvider>
                            </div>
                            <div v-if="item.tipo == 2" class="d-middle justify-content-between mb-1">
                                <p class="f-12">{{ item.nombre }} <required v-if="campoObligatorio(item.obligatorio)"/></p>
                                <ValidationProvider class="w-50" v-slot="{ errors }" :name="item.nombre" :rules="{'required': item.obligatorio == 1}">
                                    <el-input-number v-model="item.respuesta" size="small" class="w-100" controls-position="right" :min="0"></el-input-number>
                                    <vee-error :text="errors[0]"></vee-error>
                                </ValidationProvider>
                            </div>
                            <div v-if="item.tipo == 3" class="d-middle justify-content-between mb-1">
                                <p class="f-12">{{ item.nombre }} <required v-if="campoObligatorio(item.obligatorio)"/></p>
                                <ValidationProvider class="w-50" v-slot="{ errors }" :name="item.nombre" :rules="{'required': item.obligatorio == 1}">
                                    <el-select v-model="item.respuesta" size="small" class="w-100" :placeholder="item.nombre">
                                        <el-option
                                            v-for="datos in item.oportunidades_ciclos_datos_opciones"
                                            :key="datos.id"
                                            :label="datos.nombre"
                                            :value="datos.id">
                                        </el-option>
                                    </el-select>
                                    <vee-error :text="errors[0]"></vee-error>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-middle-center">
                <p class="mt-5 text-muted f-600 f-18">Ciclo no encontrado...</p>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import Aws from '~/services/aws'
import { mapGetters, mapActions } from 'vuex'
import UploadFile from './components/uploadFile.vue'
export default {
  components: { UploadFile },
    data(){
        return{
            fechaCierre: '',
            observacionAvance: '',
            estrategiaCiclo: '',
            idOportunidad: this.$route.params.id,
            idCiclo: this.$route.params.id_ciclo_ops,
            ciclo: {},
            fullscreenLoading: false,
            documents: [],
        }
    },
    async created () {
        this.getBreadcumbs(['oportunidades.main'])
        this.Action_get_info_ciclo_opportunity(this.idCiclo)
        await this.Action_get_opportunity_information(this.idOportunidad)
        this.spliceBreadcumbs([
            {
                position: 1,
                breadcumb: {
                    name: this.oportunidadesInformacion.nombre_oportunidad,
                    route: 'oportunidades.ver',
                    params: {
                        id: this.idOportunidad,
                        tipo: 'vigentes'
                    }
                }
            }
        ])
        await this.Action_get_opportunity_ciclos(this.idOportunidad)
        this.ciclo = Object.entries(this.oportunidadesCiclos).length ? this.oportunidadesCiclos.find( f => f.id_ops == this.idCiclo) : ''
        this.spliceBreadcumbs([
            {
                position: 2,
                breadcumb: {
                    name: `Ciclo: ${this.ciclo.ciclo}`,
                    route: 'oportunidades.ciclos.avanzar',
                    params: {
                        id: this.idOportunidad,
                        id_ciclo_ops: this.ciclo.id_ops,
                        tipo: 'vigentes'
                    },
                }
            }
        ])
        
    },
    computed: {
        ...mapGetters({
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
            oportunidadesCiclos: 'oportunidades/oportunidades/oportunidadesCiclos',
            infoCicloOpportunity: 'oportunidades/oportunidades/infoCicloOpportunity',
            sicloAvanzado: 'oportunidades/oportunidades/sicloAvanzado',
        }),
        additional_data(){
            return this.infoCicloOpportunity?.ciclo_actual?.oportunidades_ciclos?.oportunidades_ciclos_datos ?? []
        },
        required_documents(){
            return this.infoCicloOpportunity?.ciclo_actual?.oportunidades_ciclos?.oportunidades_ciclos_documentos ?? []
        },
        requireEstrategia(){
            if(Object.entries(this.infoCicloOpportunity).length) 
                return this.infoCicloOpportunity?.ciclo_actual?.oportunidades_ciclos?.estrategia ? 'required' : ''
        },
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            Action_get_opportunity_information: 'oportunidades/oportunidades/Action_get_opportunity_information',
            Action_get_opportunity_ciclos: 'oportunidades/oportunidades/Action_get_opportunity_ciclos',
            Action_get_info_ciclo_opportunity: 'oportunidades/oportunidades/Action_get_info_ciclo_opportunity',
            Action_advance_ciclo_opportunity: 'oportunidades/oportunidades/Action_advance_ciclo_opportunity',
        }),
        setDocuments(documents){
            this.documents = documents
        },
        campoObligatorio(item){
            return item == 1 ? '*' : ''
        },
        async subirArchivos(){
            if (this.documents.length) {
                let documentos = []
                for (let index = 0; index < this.documents.length; index++) { 
                    documentos.push({ 
                        adjunto: await Aws.uploadFile({file: this.documents[index].file, path: 'oportunidades'}),
                        id_documento: this.documents[index].id_documento
                    })
                } 
                return documentos
            }
            return []
        },
        datoAdicionalPayload(datos){
            let datosReturn = []
            if (datos.length) datos.forEach( e => {
                if (e.respuesta) datosReturn.push ( { id_dato: e.id, tipo: e.tipo, respuesta: `${e.respuesta}` } )
            })
            return datosReturn
        },
        async avanzarCiclo(){
            try {
                const documentos = await this.subirArchivos()
                const datosAdicionales = this.datoAdicionalPayload(this.additional_data)
                const payload = {
                    fecha_cierre_proximo_ciclo: this.fechaCierre,
                    observaciones_avance: this.observacionAvance,
                    estrategia_proximo_ciclo: this.estrategiaCiclo,
                    justificacion: this.observacionAvance,
                    id_nuevo_ciclo: this.infoCicloOpportunity.siguiente_ciclo.id,
                    oportunidades_ciclos_documentos: [
                        ...documentos
                    ],
                    oportunidades_ciclos_datos: [
                        ...datosAdicionales
                    ]
                }
                await this.Action_advance_ciclo_opportunity({idOportunidad: this.idOportunidad, idCiclo: this.idCiclo, payload})
            } catch (error) {
                console.error(error);
            }
        },
        validarArchivos(documentosObligatorios){
            let encontrado = false
            let docFaltante = []
            documentosObligatorios.forEach( obli => {
                this.documents.forEach( doc => {
                    if (obli.id == doc.id_documento) encontrado = true
                })
                if (!encontrado) docFaltante.push(obli)
                encontrado = false
            })
            return docFaltante
        },
        async validarAvance(){
            //validamos campos obligatorios veeValidate
            const valid = await this.$refs.validator.validate()
            if (!valid) return this.fullscreenLoading = false

            this.fullscreenLoading = true
            //validamos documentos obligatorios
            const documentos = this.required_documents
            const documentosObligatorios = documentos.filter( f => f.obligatorio == 1 )
            const documentoFaltante = this.validarArchivos(documentosObligatorios)
            if (documentoFaltante.length) {
                this.notificacionValidacion(`El documento: ${documentoFaltante[0].nombre} es obligatorio`)
                this.fullscreenLoading = false
                return
            }

            await this.avanzarCiclo()
            this.fullscreenLoading = false
            if (this.sicloAvanzado) this.cerrar()
        },
        notificacionValidacion(mensaje){
            this.$notify({title: mensaje, type:'error'})
        },
        cerrar(){
            const { tipo, id } = this.$route.params;
            this.$router.push( {name: 'oportunidades.ver', params: { tipo, id }} )
        }
    }
}
</script>

<style lang="scss" scoped>
</style>